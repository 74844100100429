.mapCharIcon {
  //filter: drop-shadow(0px 0px 1px black);
}

.hide-text div .icon-text {
  opacity: 0;
}

.icon-text {
  transition: opacity 1s ease;
}