body {
  background-color: #2a2335;
}

.App {
  text-align: center;
}

@font-face {
  font-family: "SmashFont";
  src: url('Gotham Black Regular.woff') format('woff');
}

.videobg {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  mix-blend-mode: screen;
}

.contentvisibility-auto {
  content-visibility: auto;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.flags-container {
  display: flex;
}

@keyframes list-element {
  0%   {opacity: 0; visibility: hidden;}
  100% {opacity: 1; visibility: visible;}
}

.slide-fade {
  opacity: 0;
  animation-duration: 0.1s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-play-state: running;
  animation-name: list-element;
  animation-fill-mode: forwards;
}

.player-name-container {
  flex-direction: column;
}

.flag {
  font-size: 8px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 32px;
  height: 24px;
  padding-bottom: 2px;
  line-height: 48px;
  color: white;
}

.flag span {
  color: white;
  background-color: black;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.bottom-bar {
  color: white;
  background-color: #be2018;
  font-family: SmashFont;
  font-size: 15px;
  text-align: right;
  display: inline;
}

.player-name {
  white-space: nowrap;
}

.player-avatar {
  width: 64px;
}

@media (max-width: 768px) {
  .player-score {
    width: 40px !important;
    padding: 2px !important;
  }

  .player-score div {
    font-size: 0.8rem !important;
  }

  .player-score div div {
    font-size: 0.6rem !important;
    line-height: 0.8rem !important;
  }

  .state-flag-container {
    width: 16px !important;
    padding: 0px !important;
  }

  .flags-container {
    flex-direction: column;
    line-height: 100%;
    justify-content: center;
    margin-left: 4px;
    margin-right: 4px;
    margin-top: -6px;
  }

  .flag {
    font-size: 6px;
    margin-top: 0px;
    padding-top: 0px;
    line-height: 32px;
    height: 16px !important;
  }
}

@media (max-width: 768px) {
  .firstplayer-col {
    padding-right: 0px !important
  }

  .firstplayer-col li {
    height: 350px !important;
  }

  .player-name {
    font-size: 0.7rem !important;
    line-height: 1.0rem !important;
  }

  .player-name-small {
    font-size: 0.5rem !important;
    align-self: center !important;
    white-space: normal !important;
    line-height: 0.5rem !important;
  }

  .player-main {
    width: 64px !important;
  }

  .player-main-mini {
    width: 16px !important;
    height: 16px !important;
  }

  .player-ranking {
    width: 32px !important;
  }

  .player-avatar {
    width: 48px !important;
  }

  .bottom-bar {
    font-size: 10px;
  }

  .tab {
    font-size: 0.8rem;
    padding: 6px 4px;
  }
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.0);
  border-right: 1.1em solid rgba(255, 255, 255, 0.0);
  border-bottom: 1.1em solid #ffffff;
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 .6s infinite linear;
  animation: load8 .6s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.modal {
  z-index: 99999;
}

.modal-header {
  background-color: black;
  border-bottom: 1px solid rgb(61, 84, 102);
}

.modal-backdrop {
  background-color: rgba(110, 118, 125);
}

.close {
  color: white;
}

.modal-backdrop.show {
  opacity: 0.6;
}

.modal-body {
  padding: 0;
  background-color: black;
  color: white;
}

.modal-footer {
  border-top: 1px solid rgb(61, 84, 102);
  background-color: black;
}

.tooltip {
  z-index: 999999;
}

.tooltip-inner {
  box-shadow: #ffffff 0 0 2px 1px;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: white !important;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: white !important;
}


.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: white !important;
}


.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: white !important;
}

.sidenav-container {
  position: fixed;
  width: 240px;
  max-width: none;
  height: 100%;
}

.main-container {
  padding-left: 240px;
  padding-right: 0px;
  padding-top: 20px;
}

.sidenav-container-bg {
  content: "";
  position: fixed;
  background-color: blue;
  z-index: -1;
  top: 56px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0,0,0,0.5);
  transition: ease-in-out 200ms;
  transition-property: all;
  opacity: 0;
  pointer-events: none;
}

.sidenav-container-bg-show {
  opacity: 1;
  pointer-events: all;
}

.navbar-brand {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  margin-right: 0;
  border-bottom: 1px white solid;
}

.site-logo {
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
}

.navbar-toggler {
  padding: 0.25rem;
  margin-right: 20px;
}

@media (max-width: 768px) {
  .sidenav-container {
    position: fixed;
    z-index: 2000;
    max-width: none;
    height: 56px;
    width: 100%;
    display: flex;
    top: 0;
    place-items: center;
  }

  .sidenav {
    position: fixed;
    background-color: inherit;
    width: 90%;
    left: 0;
    padding: 20px;
    transition: ease-in-out 200ms;
    height: 100%;
    top: 56px;
  }

  .sidenav-hidden {
    left: -90%;
  }

  .main-container {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 56px;
  }

  .navbar-brand {
    border-bottom: none;
  }
}

@media (max-width: 768px) {
  .modal-dialog {
    margin: 0;
    height: 100%;
    width: 100%;
    max-width: 100%;
  }

  .modal-content {
    height: 100%;
    border: 0;
  }
}