.avatar {
    width: 96px;
    height: 96px;
    background-size: cover;
    background-position: center;
    background-color: gray;
    margin: 12px;
    margin-right: 10px;
}

.characterMain {
    width: 220px;
    background-position: center 20%;
    background-size: cover;
    height: 100%;
    margin-left: auto;
    z-index: 1
}

.playerTag {
    margin-bottom: 0;
}

.fullName {
    color: white;
    font-size: 1.0rem;
}

.tttag a {
    color: white;
}

@media (max-width: 576px) {
    .stats-text {
        font-size: .6rem;
    }
}

@media (max-width: 991px) {
    .avatar {
        width: 64px;
        height: 64px;
        margin: 12px;
        margin-right: 0;
    }

    .characterMain {
        width: 100px;
        height: 140%;
    }

    .playerTag {
        font-size: 1rem;
    }

    .ttlogo {
        width: 12px !important;
        height: 12px !important;
    }

    .tttag {
        font-size: 0.6rem !important;
    }

    .stateFlag {
        width: 24px !important;
        height: 18px !important;
    }

    .fullName {
        font-size: 0.8rem;
    }
}